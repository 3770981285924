import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Alert, Button, Divider } from "@mui/material";
import BasicTable from "./table/table";
import { getSearchByQuery } from "../../Utils/api-service";

export default function PaymentForm(props) {
  const { handleShowNext } = props;
  const [phone, setPhone] = React.useState(props.formData.phone || null);
  const [showNext, setShowNext] = React.useState(false);
  const [alreadyAddress, setAlreadyAddress] = React.useState();

  React.useEffect(() => {
    handleShowNext(false);
  }, [phone]);

  React.useEffect(() => {
    setPhone(props.formData.phone);
  }, [props.formData.phone]);

  const searchNumber = () => {
    if (phone.length !== 10) {
      return;
    }
    let res = getSearchByQuery(phone);
    res
      .then((response) => {
        if (response?.data.length === 0) {
          handleShowNext(true);
        } else {
          if (response?.data[0]?.status === "NEW") {
            setShowNext(true);
            handleShowNext(true);
          } else {
            handleShowNext(false);
            setShowNext(false);
          }
          if (response?.data.length > 0) {
            setAlreadyAddress(response?.data);
          } else {
            setAlreadyAddress();
          }
        }

        props.handleMobileForm(phone);
      })
      .catch();
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Enter Customer Mobile No.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            type="number"
            id="number"
            label="Mobile no."
            fullWidth
            autoComplete="cc-name"
            variant="standard"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            maxLength={10}
          />
          {phone && phone.length > 10 && (
            <Alert severity="error">
              Mobile no. is too long — check it out!
            </Alert>
          )}
        </Grid>
        <Divider />
        <Grid item xs={4} md={4}>
          <Button onClick={searchNumber} variant="outlined">
            Search
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          {alreadyAddress && (
            <BasicTable addresses={alreadyAddress}></BasicTable>
          )}
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label="Expiry date"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
