import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Grid } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrintJsDialog(props) {
  const { addresses } = props;
  const [open, setOpen] = React.useState(props.open);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handlePrintClick(false);
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {/* <AppBar
          sx={{ position: "relative", background: "white", boxShadow: "none" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon color="secondary" />
            </IconButton>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Print
            </Button>
          </Toolbar>
        </AppBar> */}
        <Grid container spacing={3} columnSpacing={{ md: 3 }}>
          {addresses.map((add, idx) => (
            <>
              <Grid
                key={idx}
                sx={{
                  border: "1px solid #ccc",
                  mt: 3,
                  height: "500px",
                  p: 1,
                }}
                item
                xs={6}
              >
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  To,
                </Typography>
                <Typography sx={{ fontSize: 18 }}>{add.name}</Typography>
                <Typography sx={{ fontSize: 18 }}>
                  {add.addressLine1}
                </Typography>
                <Typography sx={{ fontSize: 18 }}>
                  {add.addressLine2}
                </Typography>
                <Typography sx={{ fontSize: 18 }}>
                  {add.city}, {add.state}, {add.pincode}
                </Typography>
                <Typography sx={{ fontSize: 18 }}>
                  Mobile: {add.phone}
                </Typography>

                <Typography sx={{ fontSize: 14, mt: 4 }} color="text.secondary">
                  From,
                </Typography>
                <Typography sx={{ fontSize: 18 }}>
                  Shri Balaji Babosa Mandir
                </Typography>
                <Typography sx={{ fontSize: 18 }}>
                  Babosa City, Shri Balaji Babosa Marg, Pocket 13, Sector-24,
                </Typography>
                <Typography sx={{ fontSize: 18 }}>
                  Rohini, Delhi, 110085, Mobile: 9910214967
                </Typography>
                <br></br>
                <Typography sx={{ fontSize: 14 }}>
                  <span>B:{add.bookQuantity}, </span>
                  <span>S:{add.swaroopQuantity}, </span>
                  <span>T:{add.tantiQuantity} </span>
                </Typography>
              </Grid>
            </>
          ))}
        </Grid>
      </Dialog>
    </div>
  );
}
