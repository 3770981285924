import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Divider, Grid, Typography } from "@mui/material";
import SmartGrid from "../Components/Grid/SmartGrid";
import SendIcon from "@mui/icons-material/Send";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";

export default function SamagiriList(props) {
  const navigate = useNavigate();

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };
  const goToTrackForm = () => {
    navigate("/track");
  };
  const goToAddressForm = (editMode) => {
    navigate("/add");
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Grid container>
        <Grid item xs={12} md={1}>
          <Typography variant="h5" sx={{ mb: 3 }} align={"left"}>
            Courier List
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Button
            sx={{ mb: 3 }}
            onClick={goToTrackForm}
            variant="contained"
            color="info"
            size="small"
            endIcon={<SavedSearchIcon />}
          >
            <strong>Track Courier By Mobile</strong>
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            sx={{ mb: 3 }}
            onClick={goToAddressForm}
            variant="outlined"
            size="large"
            endIcon={<SendIcon />}
          >
            <strong>Add Address</strong>
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="New" value="1" />
            <Tab label="Printed" value="2" />
            <Tab label="Dispatched" value="3" />
            <Tab label="Completed" value="4" />
            <Tab label="Declined / Return" value="5" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <SmartGrid
            handleAddress={props.handleAddress}
            status="NEW"
          ></SmartGrid>
        </TabPanel>
        <TabPanel value="2">
          <SmartGrid
            handleAddress={props.handleAddress}
            status="PRINTED"
          ></SmartGrid>
        </TabPanel>
        <TabPanel value="3">
          <SmartGrid
            handleAddress={props.handleAddress}
            status="DISPATCHED"
          ></SmartGrid>
        </TabPanel>
        <TabPanel value="4">
          <SmartGrid
            handleAddress={props.handleAddress}
            status="COMPLETED"
          ></SmartGrid>
        </TabPanel>
        <TabPanel value="5">
          <SmartGrid
            handleAddress={props.handleAddress}
            status="DECLINED"
          ></SmartGrid>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
