export const linkedin = "https://www.linkedin.com/in/akshaysinghi/";
export const api_url = "http://139.84.173.215:8080/api/v1";
// local
// export const local_api_url = "http://localhost:3001/api";
// prod
export const local_api_url = "/api";
export const categories = [
  "Animals",
  "Anime",
  "Cryptocurrency",
  "Development",
  "Photography",
  "Programming",
  "Vehicle",
  "Video",
  "Weather",
];
export const defaultCategory = "Animals";
export const apiTimeOut = 600;
export const courierPartners = [
  "SHIFA EXPRESS",
  "FLYKING",
  "TRACKON",
  "SKYKING",
  "SHREE MAHAVIR",
  "MADHUR_COURIERS",
  "POST_OFFICE",
  "DTDC",
  "BLUEDART",
  "SELF",
  "N/A",
];

export const hintTrack = (partner, trackingNumber) => {
  let link = "";
  if (partner === "SHIFA EXPRESS") {
    const checkNum = trackingNumber?.split("");
    link =
      checkNum[0].toLowerCase() === "p"
        ? "https://www.madhurcouriers.in/"
        : checkNum[0] + checkNum[1] + checkNum[2] === "322"
        ? "http://shreemahavircourier.com/"
        : checkNum[0] + checkNum[1] + checkNum[2] === "151"
        ? "http://www.flyking.in/TrackYourParcel.aspx"
        : checkNum[0] + checkNum[1] + checkNum[2] === "146"
        ? "https://trackon.in/"
        : "";
    return link;
  }
  if (partner === "POST_OFFICE") {
    return "https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx";
  }

  if (partner === "MADHUR_COURIERS") {
    return "https://www.madhurcouriers.in/";
  }

  if (partner === "SKYKING") {
    return "https://skyking.co/";
  }
  if (partner === "TRACKON") {
    return "https://trackon.in/";
  }
  if (partner === "SHREE MAHAVIR") {
    return "http://shreemahavircourier.com/";
  }

  if (partner === "FLYKING") {
    return "http://www.flyking.in/TrackYourParcel.aspx";
  }

  if (partner === "DTDC") {
    return "https://www.dtdc.in/tracking.asp";
  }
  if (partner === "BLUEDART") {
    return "https://www.bluedart.com/tracking";
  }
  return link;
};
