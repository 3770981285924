import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Chip, Grid, TextField } from "@mui/material";
import { getSearchByQuery } from "../../Utils/api-service";
import PrintJsDialog from "../PrintJSDailog/printJsDailog";

let backUpDataSet = [];

export default function SmartGrid(props) {
  const navigate = useNavigate();
  const { status, handleAddress } = props;
  const [rowsData, setRowsData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [printAddress, setPrintAddress] = React.useState([]);
  const [openPrinstJs, setOpenPrinstJs] = React.useState(false);
  const [totalSum, setTotalSum] = React.useState({
    totalSwaroop: 0,
    totalKhataBook: 0,
    totalTanti: 0,
  });
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    let res = getSearchByQuery(null, status)
      .then((resp) => {
        // console.log(resp);
        setLoading(false);
        setRowsData(resp.data);
        backUpDataSet = resp.data;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [status]);

  const onClickHandleAddress = (address, status) => {
    handleAddress(address, status);
  };
  const onClickMultipleHandleAddress = (addresses, status) => {
    handleAddress(addresses, status);
  };
  const onClickEdit = (address) => {
    navigate("edit/" + address.phone);
  };
  // Search

  // React.useEffect(() => {
  //   if (searchText) {
  //     requestSearch(searchText);
  //   } else {
  //     cancelSearch();
  //   }
  //   if (searchText?.length === 0) {
  //     alert();
  //     cancelSearch();
  //   }
  // }, [searchText]);

  const requestSearch = (searchValue) => {
    const DATASET = backUpDataSet;
    const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
    const filteredRows = DATASET.filter((o) => {
      return Object.keys(o).some((k) => {
        return searchRegex.test(o[k]?.toString());
      });
    });
    setRowsData(filteredRows);
  };

  const cancelSearch = () => {
    setSearchText("");
    setRowsData(backUpDataSet);
  };

  // Search end
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "phone",
      headerName: "Mobile No.",
      flex: 0.5,
    },
    {
      field: "addressLine1",
      headerName: "Address",
      flex: 2,
      renderCell: (params) => (
        <span>
          {params.row.addressLine1} <br /> {params.row.addressLine2},
          {params.row.city}, {params.row.state}, {params.row.pincode}
          {"    "}
          {"        -"}
          {params?.row?.courierPartner && (
            <Chip
              size="small"
              color="success"
              variant="outlined"
              label={
                params?.row?.courierPartner + " -" + params?.row?.trackingNumber
              }
            />
          )}
        </span>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: (params) => (
        <p>
          {params.row.swaroopQuantity}, {params.row.bookQuantity},{" "}
          {params.row.tantiQuantity} to {params.row.state}
        </p>
      ),
    },
    {
      field: "createDate",
      headerName: "Date",
      flex: 0.5,
      renderCell: (params) => (
        <>
          <p>{status === "NEW" && params.row.createDate}</p>
          <p>{status === "DISPATCHED" && params.row.dispatchDate}</p>
          <p>{status === "COMPLETED" && params.row.completionDate}</p>
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
    },
  ];

  const coloumnWithMenu = columns.map((column, idx) => {
    if (column.field === "action") {
      return {
        ...column,
        renderCell: (params) => (
          <>
            <div key={idx}>
              {status !== "NEW" && (
                <Button
                  onClick={() => onClickHandleAddress(params.row, "VIEW")}
                  size="small"
                >
                  View
                </Button>
              )}
              {status === "NEW" && (
                <Button
                  onClick={() => onClickHandleAddress(params.row, "PRINTED")}
                  color="warning"
                  size="small"
                >
                  Printed
                </Button>
              )}
              {status === "PRINTED" && (
                <Button
                  onClick={() => onClickHandleAddress(params.row, "DISPATCHED")}
                  color="warning"
                >
                  Dispatched
                </Button>
              )}
              {status === "DISPATCHED" && (
                <Button
                  onClick={() => onClickHandleAddress(params.row, "COMPLETED")}
                  color="success"
                  size="small"
                >
                  Completed
                </Button>
              )}
              {status === "DISPATCHED" && (
                <Button
                  onClick={() => onClickHandleAddress(params.row, "DECLINED")}
                  color="error"
                  size="small"
                >
                  Declined
                </Button>
              )}
              {status && status === "NEW" && (
                <Button onClick={() => onClickEdit(params.row)}>Edit</Button>
              )}
            </div>
          </>
        ),
      };
    }
    return column;
  });

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const handleRowSelection = (selection) => {
    setRowSelectionModel(selection);
    // console.log(rowsData);
    let addressess = [];
    let sum = {
      totalSwaroop: 0,
      totalKhataBook: 0,
      totalTanti: 0,
    };
    if (rowsData.length > 0 && selection.length > 0) {
      for (let index = 0; index < selection.length; index++) {
        for (let idx = 0; idx < rowsData.length; idx++) {
          const element = rowsData[idx];
          if (element.id === selection[index]) {
            addressess.push(element);
            sum.totalSwaroop += element.swaroopQuantity;
            sum.totalKhataBook += element.bookQuantity;
            sum.totalTanti += element.tantiQuantity;
          }
        }
      }
    }
    setTotalSum(sum);
    setPrintAddress(addressess);
  };

  const handlePrintClick = (data) => {
    setOpenPrinstJs(data);
  };

  return openPrinstJs ? (
    <PrintJsDialog
      handlePrintClick={handlePrintClick}
      open={openPrinstJs}
      addresses={printAddress}
    />
  ) : (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          {status === "NEW" && (
            <Button
              onClick={() => handlePrintClick(true)}
              variant="contained"
              color="success"
              size="large"
            >
              Print {rowSelectionModel.length} Address
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {status && (
            <strong>
              Selected Addresses: {rowSelectionModel.length} Swaroop:{" "}
              {totalSum.totalSwaroop}, Khata Book: {totalSum.totalKhataBook},
              Tanti: {totalSum.totalTanti}
            </strong>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          {status === "NEW" && rowSelectionModel.length > 0 && (
            <Button
              onClick={() =>
                onClickMultipleHandleAddress(printAddress, "PRINTED")
              }
              variant="outlined"
              color="success"
              size="small"
            >
              Mark {rowSelectionModel.length} Address as Printed
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid sx={{ mt: 4, mb: 4 }} spacing={1} container>
        <Grid item xs={12} md={9}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="courierPartner"
            placeholder="Search By Anything * (Name, Phone No., Tracking No., Courier Partner)"
            label="Search"
            type="text"
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            size="large"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => requestSearch(searchText)}
            size="large"
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            onClick={() => cancelSearch()}
            size="large"
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}></Stack>
      <DataGrid
        // pageSizeOptions={[]}
        autoHeight
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleRowSelection}
        rowSelectionModel={rowSelectionModel}
        columns={coloumnWithMenu}
        rows={rowsData}
        loading={loading}
      />
    </Box>
  );
}
