import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { linkedin } from "../../Utils/constant";
import { Button } from "@mui/material";
import { getLocalStorage } from "../../Utils/util-service";

const Header = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    // window.open(linkedin, "_BLANK");
    localStorage.setItem("authToken", null);
    localStorage.setItem("username", null);
    localStorage.setItem("permissions", null);
    localStorage.setItem("check", null);
    navigate("/login");
  };

  React.useEffect(() => {
    const authToken = getLocalStorage("check");
    if (authToken) {
      handleLogout();
    }
  }, []);
  return (
    <>
      <AppBar color="primary">
        <Container>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                flexGrow: 1,
                display: { xs: "flex", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              11 Mangalwar
            </Typography>

            <Box>
              <Button variant="contained" onClick={handleLogout}>
                LOGOUT
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Typography variant="h5" sx={{ mt: 10 }} align={"left"}></Typography>
    </>
  );
};
export default Header;
