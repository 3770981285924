import * as React from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Chip,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { searchMobileIfRegistered } from "../Utils/api-service";

export default function RegistrationCheck() {
  const [phoneNumCheck, setPhoneNumCheck] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const [pLoading, setPloading] = React.useState(false);
  const [showERrorr, setShowERrorr] = React.useState(false);

  React.useEffect(() => {
    if (phoneNumCheck) {
      setShowERrorr(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumCheck]);

  const searchMobile = () => {
    setUsers([]);
    setPloading(true);
    searchMobileIfRegistered(phoneNumCheck)
      .then((res) => {
        setUsers(res?.data);
        setPloading(false);
        setShowERrorr(res?.data.length === 0);
      })
      .catch((err) => {
        setPloading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Paper
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 396,
          borderRadius: 0,
          mt: 15,
        }}
      >
        <IconButton color="primary" sx={{ p: 2, mt: -2 }} aria-label="menu">
          <PersonSearchIcon />
          {pLoading && <CircularProgress sx={{ position: "absolute" }} />}
        </IconButton>
        <TextField
          fullWidth
          label="Search Mobile Number"
          placeholder="if registered"
          color="primary"
          onChange={(e) => setPhoneNumCheck(e.target.value)}
          helperText={`${
            phoneNumCheck ? phoneNumCheck.length : 0
          }/10: Enter valid 10  digit number.`}
          error={phoneNumCheck.length > 10}
          focused
        />

        <IconButton
          disabled={phoneNumCheck.length !== 10}
          color="primary"
          size="large"
          sx={{ mt: -2 }}
          aria-label="search"
          onClick={searchMobile}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      {users.length > 0 && (
        <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
          <Table>
            {/* <TableHead>
            <TableRow>
              <TableCell> Name</TableCell>
              <TableCell>Pincode</TableCell>
            </TableRow>
          </TableHead> */}
            <TableBody>
              {users.map((row, idx) => (
                <TableRow key={row.name + idx}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell component="th" scope="row">
                    {row.name} <br /> {row.phone}
                  </TableCell>
                  <TableCell>
                    {row.pincode} <br /> {row.state}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {showERrorr && (
        <Alert sx={{ alignItems: "center" }} severity="success" color="error">
          No user record found with - <strong>{phoneNumCheck}</strong>
        </Alert>
      )}
      <Divider sx={{ mt: 6 }}>
        <Chip label="OR" />
      </Divider>
    </>
  );
}
