import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, Chip, CircularProgress, Grid, Link } from "@mui/material";
import { postAddressForm } from "../../Utils/api-service";
import { courierPartners, hintTrack } from "../../Utils/constant";

export default function UpdateStatus(props) {
  const [address, setAddress] = React.useState(props.address);
  const [open, setOpen] = React.useState(props.open || false);
  const [showError, setShowError] = React.useState(false);
  const [disableClose, setDisableClose] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.closeSetOpen(false);
  };

  const setStatusToServer = (status) => {
    // setAddress((prevData) => ({
    //   ...prevData,
    //   status,
    // }));
    setDisableClose(true);

    if (address && address.length > 0) {
      for (let index = 0; index < address.length; index++) {
        const singleAdd = address[index];
        singleAdd.status = status;
        let arrRes = postAddressForm(singleAdd, true)
          // eslint-disable-next-line no-loop-func
          .then((res) => {
            if (res?.response?.status && res?.response?.status !== 200) {
              setShowError(true);
            }
            if (index === address.length - 1) {
              setOpen(false);
              props.closeSetOpen(false);
              setDisableClose(false);
            }
          })
          .catch((err) => {
            console.log(err);
            console.log("errr", err);
            setDisableClose(false);
          });
      }
    } else {
      setTimeout(() => {
        let add = address;
        add.status = status === "VIEW" ? address.status : status;
        let res = postAddressForm(add, true)
          .then((res) => {
            if (res?.response?.status && res?.response?.status !== 200) {
              setShowError(true);
            } else {
              setOpen(false);
              props.closeSetOpen(false);
            }
            setDisableClose(false);
          })
          .catch((err) => {
            console.log(err);
            console.log("errr", err);
            setDisableClose(false);
          });
      }, 500);
    }
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog disableEscapeKeyDown open={open}>
        <DialogTitle>
          Update Status for{" "}
          {address.length > 0
            ? address.map((val) => {
                return `${val.phone}, `;
              })
            : address.phone}{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {address.phone && (
              <>
                {address.name} <br />
                {address.addressLine1} <br /> {address.addressLine2},<br />
                {address.city}, {address.state}, {address.pincode}
                <br />
                <br />
                {"Address added: " + address.createDate}
                <br />
                {address.dispatchDate &&
                  "Address dispatched: " + address.dispatchDate}
                <br />
                {address.completionDate &&
                  "Address dispatched: " + address.completionDate}
              </>
            )}
            <br />
            {props.status && (
              <>
                {" "}
                You can change the status to <strong> {props.status} </strong>
              </>
            )}
            <br />
            <br />
          </DialogContentText>
          {props.status !== "PRINTED" && (
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="courierPartner"
                  label="Courier Partner"
                  type="text"
                  fullWidth
                  value={address.courierPartner}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setAddress((prevData) => ({
                      ...prevData,
                      courierPartner: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="trackingNumber"
                  label="Tracking Number"
                  type="text"
                  fullWidth
                  value={address.trackingNumber}
                  onChange={(e) =>
                    setAddress((prevData) => ({
                      ...prevData,
                      trackingNumber: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="courierId"
                  label="Tracking Link"
                  type="url"
                  fullWidth
                  value={address.courierId}
                  onChange={(e) =>
                    setAddress((prevData) => ({
                      ...prevData,
                      courierId: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
          )}
          <br />

          {props.status === "DISPATCHED" &&
            courierPartners.map((val, idx) => (
              <Chip
                key={idx}
                size="medium"
                label={val}
                variant={
                  val === address.courierPartner ? "contained" : "outlined"
                }
                color="primary"
                onClick={() =>
                  setAddress((prevData) => ({
                    ...prevData,
                    courierPartner: val,
                  }))
                }
                sx={{ mr: 1, mt: 1 }}
              />
            ))}
          {props.status === "DECLINED" && (
            <TextField
              autoFocus
              required
              margin="dense"
              id="remark"
              label="Remark"
              type="text"
              fullWidth
              value={address.courierId}
              onChange={(e) =>
                setAddress((prevData) => ({
                  ...prevData,
                  remark: e.target.value,
                }))
              }
            />
          )}
          {(props.status === "COMPLETED" ||
            address.status === "DISPATCHED") && (
            <Alert severity="info">
              You can track this through:-{" "}
              <Link
                href={hintTrack(address.courierPartner, address.trackingNumber)}
                target="_BLANK"
              >
                {hintTrack(address.courierPartner, address.trackingNumber)}
              </Link>{" "}
            </Alert>
          )}
        </DialogContent>
        {showError && (
          <Alert severity="error">
            Due to some problem unable to change the status.
          </Alert>
        )}
        <DialogActions>
          {disableClose && <CircularProgress />}
          <Button disabled={disableClose} onClick={handleClose}>
            Close
          </Button>
          {props.status === "PRINTED" && (
            <Button
              variant="contained"
              onClick={() => setStatusToServer("PRINTED")}
              color="success"
            >
              Printed
            </Button>
          )}
          {props.status === "DISPATCHED" && (
            <Button
              variant="contained"
              onClick={() => setStatusToServer("DISPATCHED")}
              color="warning"
            >
              Dispatched
            </Button>
          )}
          {props.status === "COMPLETED" && (
            <Button
              variant="contained"
              onClick={() => setStatusToServer("COMPLETED")}
              color="success"
            >
              Completed
            </Button>
          )}
          {props.status === "DECLINED" && (
            <Button
              variant="contained"
              onClick={() => setStatusToServer("DECLINED")}
              color="error"
            >
              Declined
            </Button>
          )}
          {/* {props.status === "VIEW" && (
            <Button
              variant="contained"
              onClick={() => setStatusToServer("VIEW")}
              color="error"
            >
              Update
            </Button>
          )} */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
