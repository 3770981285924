import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AddressForm from "./AddressForm";
import MobileForm from "./MobileForm";
import Review from "./Review";
import Products from "./Products";
import Header from "../../Components/Header/Header";
import { getSearchByQuery, postAddressForm } from "../../Utils/api-service";

export default function Checkout() {
  const { itemId } = useParams();
  const navigate = useNavigate();
  console.log("paramss", itemId);
  const [formData, setFormData] = React.useState({
    country: "INDIA",
    status: "NEW",
  });
  const [editMode, setEditMode] = React.useState(false);

  React.useEffect(() => {
    getSearchByQuery(itemId)
      .then((res) => {
        console.log("--->", res?.status);
        if (res?.status === 200 && res?.data.length > 0) {
          setFormData(res.data[0]);
          setEditMode(true);
        }
      })
      .catch((err) => {
        console.log("---> error", err);
      });
  }, [itemId]);

  const [showProceed, setShowProceed] = React.useState(true);
  const [showNext, setShowNext] = React.useState(false);

  const handleShowNext = (val) => {
    setShowNext(val);
  };

  const handleMobileForm = (phone) => {
    console.log(phone);
    // setFormData({ phone });
    setFormData((prevData) => ({
      ...prevData,
      phone,
    }));
  };
  const handleAddressForm = (data) => {
    // setFormData((prevData) => ({
    //   ...prevData,
    //   name: data.name,
    //   addressLine1: data.addressLine1,
    // }));
    console.log(data);
    setFormData(data);
  };

  const handleProductForm = (swaroopQuantity, bookQuantity, tantiQuantity) => {
    setFormData((prevData) => ({
      ...prevData,
      swaroopQuantity: parseInt(swaroopQuantity),
      bookQuantity: parseInt(bookQuantity),
      tantiQuantity: parseInt(tantiQuantity),
    }));
  };

  const sendAddressForm = () => {
    let res = postAddressForm(formData, editMode)
      .then((res) => {
        console.log(res);
        setActiveStep(steps.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToList = (editMode) => {
    navigate("/");
  };

  const steps = [
    "Customer details",
    "Shipping address",
    "Products",
    "Review your order",
  ];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <MobileForm
            formData={formData}
            handleShowNext={handleShowNext}
            handleMobileForm={handleMobileForm}
          />
        );
      case 1:
        return (
          <AddressForm
            formData={formData}
            handleShowNext={handleShowNext}
            handleAddressForm={handleAddressForm}
          />
        );
      case 2:
        return (
          <Products
            handleProductForm={handleProductForm}
            handleShowNext={handleShowNext}
            formData={formData}
          />
        );
      case 3:
        return <Review formData={formData} />;
      default:
        throw new Error("Unknown step");
    }
  }
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <Container component="main" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            {editMode ? "Edit Details" : "Create"}
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order is created. We have SMS the order confirmation.
                <Button onClick={goToList}>Go to list</Button>
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                {showProceed && showNext && activeStep !== steps.length - 1 && (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Next
                  </Button>
                )}
                {showProceed && showNext && activeStep === steps.length - 1 && (
                  <Button
                    variant="contained"
                    onClick={sendAddressForm}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Place order
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
}
