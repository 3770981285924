import * as React from "react";
import { Container } from "@mui/material";
import Header from "../Components/Header/Header";
import SamagiriList from "./SamagiriList";
import UpdateStatus from "../Components/UpdateStatus/UpdateStatus";
import Registration from "./Registration";

const SelectDashboard = () => {
  const [perms, setPerms] = React.useState([]);

  React.useEffect(() => {
    let data = localStorage.getItem("permissions");
    if (data) {
      setPerms(JSON.parse(data));
    }
  }, []);

  const [address, setAddress] = React.useState({});
  const [deliveryStatus, setDeliveryStatus] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const handleAddress = (address, status) => {
    console.log("->>>> ", address);
    setAddress(address);
    setOpen(!open);
    setDeliveryStatus(status);
  };

  return (
    <>
      <Container maxWidth={"m"}>
        {open && (
          <UpdateStatus
            address={address}
            open={open}
            closeSetOpen={setOpen}
            status={deliveryStatus}
          />
        )}
        <Header />
        {perms && perms.includes("VRAT_SAMAGRI") && (
          <SamagiriList handleAddress={handleAddress} />
        )}
        {perms && perms.includes("VRAT_REGISTER") && (
          <Registration perms={perms} />
        )}
      </Container>
    </>
  );
};
export default SelectDashboard;
