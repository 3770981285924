import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import ErrorPage from "./error/error-page";
import reportWebVitals from "./reportWebVitals";
import Login from "./Containers/Login";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Checkout from "./Containers/checkout/Checkout";
import PaymentForm from "./Containers/checkout/MobileForm";
import TrackForm from "./Containers/Track";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "add",
    element: <Checkout />,
  },
  {
    path: "track",
    element: <TrackForm handleShowNext={() => {}} />,
  },
  {
    path: "edit/:itemId",
    element: <Checkout />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
