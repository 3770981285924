import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";

const product = [
  {
    name: "Swaroop",
    desc: "",
    key: "swaroopQuantity",
  },
  {
    name: "Khata Book",
    desc: "",
    key: "bookQuantity",
  },
  {
    name: "Tanti",
    desc: "",
    key: "tantiQuantity",
  },
];

export default function Review(props) {
  const { formData } = props;
  const [address] = React.useState(formData);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={product[0].name} secondary={product[0].desc} />
          <Typography variant="body2">{address.swaroopQuantity}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={product[1].name} secondary={product[0].desc} />
          <Typography variant="body2">{address.bookQuantity}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={product[2].name} secondary={product[0].desc} />
          <Typography variant="body2">{address.tantiQuantity}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {parseInt(address.swaroopQuantity) +
              parseInt(address.bookQuantity) +
              parseInt(address.tantiQuantity)}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Shipping
          </Typography>
          <Typography gutterBottom>John Smith</Typography>
          <Typography gutterBottom>{addresses.join(", ")}</Typography>
        </Grid> */}
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Shipping details
          </Typography>
          <Grid container>
            <React.Fragment>
              <Grid item xs={6}>
                <Typography gutterBottom>Name:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{address.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>Mobile No.:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{address.phone}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>Address Line 1:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{address.addressLine1}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>Address Line 2:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{address.addressLine2}</Typography>
              </Grid>
              {/*  */}
              <Grid item xs={6}>
                <Typography gutterBottom>Pincode:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{address.pincode}</Typography>
              </Grid>
              {/*  */}
              {/*  */}
              <Grid item xs={6}>
                <Typography gutterBottom>District:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>{address.district}</Typography>
              </Grid>
              {/*  */}
              {/*  */}
              <Grid item xs={6}>
                <Typography gutterBottom>City, State, Country :</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>
                  {address.city}, {address.state}, {address.country}
                </Typography>
              </Grid>
              {/*  */}
            </React.Fragment>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
