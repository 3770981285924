import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { Button, Divider, TextField } from "@mui/material";

const product = [
  {
    name: "Swaroop",
    desc: "",
    key: "swaroopQuantity",
  },
  {
    name: "Khata Book",
    desc: "",
    key: "bookQuantity",
  },
  {
    name: "Tanti",
    desc: "",
    key: "tantiQuantity",
  },
];

export default function Review(props) {
  const { handleProductForm, handleShowNext } = props;
  const [address, setAddress] = React.useState(props.formData || {});
  const [swaroopQuantity, setSwaroopQuantity] = React.useState(
    props?.formData?.swaroopQuantity || 1
  );
  const [bookQuantity, setBookQuantity] = React.useState(
    props?.formData?.bookQuantity || 1
  );
  const [tantiQuantity, setTantiQuantity] = React.useState(
    props?.formData?.tantiQuantity || 1
  );

  React.useEffect(() => {
    handleShowNext(false);
  }, [address]);

  function updateParent() {
    handleShowNext(true);
    handleProductForm(swaroopQuantity, bookQuantity, tantiQuantity);
  }
  // React.useEffect(() => {
  //   // handleShowNext(false);
  //   console.log(address);
  //   // swaroopQuantity: 1,
  //   // bookQuantity: 1,
  //   // tantiQuantity: 1,
  //   setAddress(props.formData);
  // }, [address.swaroopQuantity]);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Add Product Quantity
      </Typography>
      <List disablePadding>
        <>
          <ListItem sx={{ py: 1 }}>
            <ListItemText
              primary={product[0].name}
              secondary={product[0].desc}
            />
            <Grid item xs={12} md={12}>
              <TextField
                required
                type="number"
                id="cardName"
                label="Quantity"
                fullWidth
                autoComplete="cc-name"
                value={swaroopQuantity}
                onChange={(e) => setSwaroopQuantity(e.target.value)}
              />
            </Grid>
          </ListItem>
          <Divider />
          <ListItem sx={{ py: 1 }}>
            <ListItemText
              primary={product[1].name}
              secondary={product[1].desc}
            />
            <Grid item xs={12} md={12}>
              <TextField
                required
                type="number"
                id="cardName"
                label="Quantity"
                fullWidth
                autoComplete="cc-name"
                value={bookQuantity}
                onChange={(e) => setBookQuantity(e.target.value)}
              />
            </Grid>
          </ListItem>
          <Divider />
          <ListItem sx={{ py: 1 }}>
            <ListItemText
              primary={product[2].name}
              secondary={product[2].desc}
            />
            <Grid item xs={12} md={12}>
              <TextField
                required
                type="number"
                id="cardName"
                label="Quantity"
                fullWidth
                autoComplete="cc-name"
                value={tantiQuantity}
                onChange={(e) => setTantiQuantity(e.target.value)}
              />
            </Grid>
          </ListItem>
        </>
        <Grid container>
          <Grid item xs={12} sm={10}>
            <ListItem sx={{ py: 1 }}>
              <ListItemText primary="Total" />
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                {parseInt(swaroopQuantity) +
                  parseInt(bookQuantity) +
                  parseInt(tantiQuantity)}
              </Typography>
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              onClick={() => updateParent()}
              size="large"
              variant="contained"
            >
              Proceed
            </Button>
          </Grid>
        </Grid>
      </List>
    </React.Fragment>
  );
}
