import { api_url, defaultCategory, local_api_url } from "./constant";
import { category, getLocalStorage } from "./util-service";
import axios from "axios";
// export const getDataByTitleAndCategory = (keyword) => {
//   let categorySelected = category || defaultCategory;
//   return fetch(`${api_url}?title=${keyword}&Category=${categorySelected}`, {
//     cache: "force-cache",
//   });
// };
let authToken;
let username;

function getAuthDetails() {
  authToken = getLocalStorage("authToken");
  username = getLocalStorage("username");

  // console.log("service authToken", authToken);
}

export const postLogin = async (obj) => {
  try {
    const response = await axios.post(`${api_url}/login`, obj);
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

export const postAddressForm = async (formData, editMode) => {
  getAuthDetails();
  try {
    let response;
    if (editMode) {
      response = await axios.put(
        `${api_url}/samagri/${formData.id}?authToken=${authToken}&username=${username}`,
        formData
      );
    } else {
      response = await axios.post(
        `${api_url}/samagri?authToken=${authToken}&username=${username}`,
        formData
      );
    }
    // console.log("Data sent:", response.data);
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
    return error;
  }
};

export const getSearchByQuery = async (mNo, status) => {
  getAuthDetails();
  let url = `${api_url}/samagri?authToken=${authToken}&username=${username}`;
  if (mNo) {
    url += `&phone=${mNo}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  try {
    const response = await axios.get(url);
    // console.log("Mumber search:", response.data);
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      localStorage.setItem("check", true);
    }
  }
};

export const postPincode = async (code) => {
  try {
    const response = await axios.post(`${local_api_url}/pincode/${code}`, {});
    // console.log("Pincode sent:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

export const postRegistration = async (formData) => {
  getAuthDetails();
  try {
    let response;
    response = await axios.post(
      `${api_url}/registration?authToken=${authToken}&username=${username}`,
      formData
    );

    // console.log("Data sent:", response.data);
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
    return error;
  }
};

export const searchMobileIfRegistered = async (mobilenumber) => {
  getAuthDetails();
  try {
    let response;
    response = await axios.get(
      `${api_url}/registration?authToken=${authToken}&username=${username}&phone=${mobilenumber}`
    );

    // console.log("Data sent:", response.data);
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
    return error;
  }
};
