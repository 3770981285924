import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { postRegistration, postPincode } from "../Utils/api-service";
import { Alert, Snackbar } from "@mui/material";
import RegistrationCheck from "./RegistrationCheck";

const defaultTheme = createTheme();

export default function Registration(props) {
  const [reload, setReload] = React.useState(true);

  const [showPincodeError, setShowPincodeError] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [showPhoneError, setShowPhoneError] = React.useState(false);
  const [phoneData, setPhoneData] = React.useState("");
  const [dataSaveMsg, setDataSaveMsg] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let dataToSend = {
      name: data.get("name"),
      phone: data.get("phone"),
      pincode: data.get("pincode"),
    };
    setShowPhoneError(dataToSend.phone.length !== 10);
    setShowPincodeError(dataToSend.pincode.length !== 6);

    if (dataToSend.phone.length === 10 && dataToSend.pincode.length === 6) {
      setLoading(true);
      console.log(dataToSend);
      dataToSend.count = 1;
      dataToSend.country = "India";
      // fetch pincode

      const pincodeRenponse = postPincode(dataToSend.pincode)
        .then((pincodes) => {
          console.log("data", pincodes);
          if (pincodes && pincodes?.length !== 0) {
            dataToSend.district = pincodes[0].district;
            dataToSend.state = pincodes[0].stateName;
            sendData();
          } else {
            sendData();
          }
        })
        .catch((err) => {
          console.log("error---", err);
        });
      //

      function sendData() {
        let res = postRegistration([dataToSend])
          .then((res) => {
            console.log("resss", res);
            if (res.status === 200) {
              setReload(false);
              setTimeout(() => {
                setReload(true);
                setDataSaveMsg(true);
                setLoading(false);
              }, 300);
              setTimeout(() => {
                setDataSaveMsg(false);
              }, 5000);
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const valdatePincode = (e) => {
    const pincode = e.target.value;
    if (pincode.length !== 6) {
      setShowPincodeError(true);
    } else {
      setShowPincodeError(false);
      setShowError(false);
    }
  };

  const valdatePhone = (e) => {
    const phone = e.target.value;
    setPhoneData(phone.length);
    if (phone.length !== 10) {
      setShowPhoneError(true);
    } else {
      setShowPhoneError(false);
      setShowError(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {props?.perms.includes("VRAT_VALIDATE") && <RegistrationCheck />}
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <GroupAddOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Vrat Registration
          </Typography>
          {reload && (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Full Name"
                name="name"
                autoComplete="name"
                autoFocus
                type="text"
                helperText={`Enter valid name.`}
                variant="standard"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="phone"
                label="Mobile No. &#127470;&#127475;"
                type="number"
                id="phone"
                variant="standard"
                error={showPhoneError}
                helperText={`${
                  phoneData ? phoneData : 0
                }/10: Enter valid 10  digit number.`}
                onChange={(e) => valdatePhone(e)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="pincode"
                label="Pincode"
                type="number"
                id="pincode"
                variant="standard"
                error={showPincodeError}
                helperText={showPincodeError && "Enter valid 6 digit pincode."}
                onChange={(e) => valdatePincode(e)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={
                  showPincodeError || showPhoneError || showError || loading
                }
              >
                {loading ? "Sending..." : "Register"}
              </Button>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </Box>
          )}
          <Snackbar open={dataSaveMsg} autoHideDuration={4000}>
            <Alert severity="success" sx={{ width: "100%" }}>
              Record saved successfully!
            </Alert>
          </Snackbar>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
