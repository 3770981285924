import * as React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Container,
  CssBaseline,
  Divider,
  Link,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { getSearchByQuery } from "../Utils/api-service";
import Header from "../Components/Header/Header";
import { hintTrack } from "../Utils/constant";

export default function TrackForm(props) {
  const navigate = useNavigate();
  const [phone, setPhone] = React.useState("");
  const [showNext, setShowNext] = React.useState(false);
  const [alreadyAddress, setAlreadyAddress] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [noResult, setNoResult] = React.useState(false);
  const [oldPhone, setOldPhone] = React.useState("");

  const searchNumber = () => {
    if (phone.length !== 10) {
      return;
    }
    setLoading(true);
    setOldPhone("");
    let res = getSearchByQuery(phone);
    res
      .then((response) => {
        setLoading(false);
        if (response?.data.length === 0) {
          setOldPhone(phone);
          setNoResult(true);
        } else {
          if (response?.data[0]?.status === "NEW") {
            setShowNext(true);
          } else {
            setShowNext(false);
          }
          if (response?.data.length > 0) {
            setNoResult(false);
            setAlreadyAddress(response?.data[0]);
          } else {
            setAlreadyAddress();
          }
        }

        props.handleMobileForm(phone);
      })
      .catch();
  };

  const onBackClick = () => {
    navigate("/");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <Container component="main" sx={{ mb: 4 }}>
        <IconButton
          onClick={onBackClick}
          color="primary"
          sx={{ p: 0, ml: 0, mt: 0, mb: 2 }}
          aria-label="menu"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>
          Enter Customer Mobile No.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextField
              type="number"
              id="number"
              label="Mobile no."
              fullWidth
              autoComplete="cc-name"
              variant="standard"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              maxLength={10}
            />
            {phone && phone.length > 10 && (
              <Alert severity="error">
                Mobile no. is too long — check it out!
              </Alert>
            )}
          </Grid>
          <Divider />
          <Grid item xs={4} md={4}>
            <Button onClick={searchNumber} variant="outlined">
              Search
            </Button>
          </Grid>
        </Grid>
        {noResult && (
          <Alert sx={{ mt: 4 }} severity="error">
            <AlertTitle>No Results</AlertTitle>
            There is no result associated with — <strong> {oldPhone} </strong>
          </Alert>
        )}
        {/*  */}
        {!noResult && (
          <Grid item container direction="column" xs={12} sm={6}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Courier details
            </Typography>
            <Grid container>
              <React.Fragment>
                <Grid item xs={6}>
                  <Typography gutterBottom>Current Status:</Typography>
                </Grid>
                <Grid item xs={6}>
                  {alreadyAddress?.status && (
                    <Chip
                      size="small"
                      label={alreadyAddress?.status}
                      color="primary"
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>Courier from:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    {alreadyAddress?.courierPartner}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>Tracking No:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong> {alreadyAddress?.trackingNumber} </strong>
                    <br />
                    {(alreadyAddress.status === "COMPLETED" ||
                      alreadyAddress.status === "DISPATCHED") && (
                      <Link
                        href={hintTrack(
                          alreadyAddress.courierPartner,
                          alreadyAddress.trackingNumber
                        )}
                        target="_BLANK"
                      >
                        {hintTrack(
                          alreadyAddress.courierPartner,
                          alreadyAddress.trackingNumber
                        )}
                      </Link>
                    )}
                  </Typography>
                </Grid>
                <br />
                <br />
                <br />
                <Grid item xs={6}>
                  <Typography gutterBottom>Dispatch date:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    {alreadyAddress?.dispatchDate}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>Create date:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    {alreadyAddress?.createDate}
                  </Typography>
                </Grid>
                <br />
                <Grid sx={{ mt: 4 }} item xs={6}>
                  <Typography gutterBottom>Name:</Typography>
                </Grid>
                <Grid sx={{ mt: 4 }} item xs={6}>
                  <Typography gutterBottom>{alreadyAddress?.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>Mobile No.:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{alreadyAddress.phone}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>Address Line 1:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    {alreadyAddress.addressLine1}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>Address Line 2:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    {alreadyAddress.addressLine2}
                  </Typography>
                </Grid>
                {/*  */}
                <Grid item xs={6}>
                  <Typography gutterBottom>Pincode:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{alreadyAddress.pincode}</Typography>
                </Grid>
                {/*  */}
                {/*  */}
                <Grid item xs={6}>
                  <Typography gutterBottom>District:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    {alreadyAddress.district}
                  </Typography>
                </Grid>
                {/*  */}
                {/*  */}
                <Grid item xs={6}>
                  <Typography gutterBottom>City, State, Country :</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    {alreadyAddress.city}, {alreadyAddress.state},{" "}
                    {alreadyAddress.country}
                  </Typography>
                </Grid>
                {/*  */}
                <Grid item xs={6}>
                  <Typography gutterBottom>Quantity :</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>
                    S: {alreadyAddress.swaroopQuantity}, B:{" "}
                    {alreadyAddress.bookQuantity}, T:{" "}
                    {alreadyAddress.tantiQuantity}
                  </Typography>
                </Grid>
              </React.Fragment>
            </Grid>
          </Grid>
        )}

        {/*  */}
      </Container>
    </React.Fragment>
  );
}
