import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Alert, Button } from "@mui/material";
import { postPincode } from "../../Utils/api-service";

export default function AddressForm(props) {
  const { handleShowNext, handleAddressForm } = props;
  const [address, setAddress] = React.useState(props.formData || {});
  const [editMode, setEditMode] = React.useState(true);
  const [showError, setShowError] = React.useState(false);

  React.useEffect(() => {
    handleShowNext(false);
  }, [address]);

  React.useEffect(() => {
    if (address.pincode && address.pincode.length !== 6) {
      setShowError(true);
    }
    if (address.pincode && address.pincode.length === 6 && !editMode) {
      console.log(address.pincode);
      getPinCodes(address.pincode);
    }
  }, [address.pincode]);

  const getPinCodes = (code) => {
    const res = postPincode(code);
    res.then((pincodes) => {
      console.log("data", pincodes);
      if (pincodes && pincodes?.length != 0) {
        setAddress((prevData) => ({
          ...prevData,
          district: pincodes[0].district,
          state: pincodes[0].stateName,
        }));
        setShowError(false);
      } else {
        setShowError(true);
      }
    });
  };

  function updateParent() {
    handleShowNext(true);
    handleAddressForm(address);
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Full name"
            fullWidth
            autoComplete="given-name"
            value={address.name}
            onChange={(e) =>
              setAddress((prevData) => ({
                ...prevData,
                name: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            id="MobileNo"
            name="MobileNo"
            label="Mobile No"
            fullWidth
            value={address.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
            value={address.addressLine1}
            onChange={(e) =>
              setAddress((prevData) => ({
                ...prevData,
                addressLine1: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
            value={address.addressLine2}
            onChange={(e) =>
              setAddress((prevData) => ({
                ...prevData,
                addressLine2: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Pin code"
            fullWidth
            autoComplete="shipping postal-code"
            value={address.pincode}
            onChange={(e) => {
              setAddress((prevData) => ({
                ...prevData,
                pincode: e.target.value,
              }));
              setEditMode(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            value={address.city}
            onChange={(e) =>
              setAddress((prevData) => ({
                ...prevData,
                city: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="district"
            name="district"
            label="District"
            fullWidth
            value={address.district}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            value={address.state}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
            value={address.country}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            onClick={() => updateParent()}
            size="large"
            variant="contained"
            disabled={showError}
          >
            Proceed
          </Button>
          {showError && (
            <Alert severity="error">
              Some problem! Please check the details you have entered.
            </Alert>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
